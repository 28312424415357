import { Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import pick from "lodash-es/pick"
import React from "react"
import { Json } from "../../components/Json"
import { NoSsr } from "../../components/NoSsrContext"
import { useUser } from "../../components/auth/FirebaseContext"
import { DebugLayout } from "../../components/debug/DebugLayout"

function Other() {
  const { user, isLoading } = useUser()

  return (
    <>
      {isLoading && <span>Loading user...</span>}
      <br />
      <Json>
        {{
          user: pick(user, ["email", "emailVerified", "uid"]),
        }}
      </Json>
    </>
  )
}

export default function OtherPage() {
  return (
    <DebugLayout title="Other Page">
      <Link to="/debug">Debug →</Link>{" "}
      <NoSsr>
        <Other />
      </NoSsr>
    </DebugLayout>
  )
}
